import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/circleci/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "national-stop-register-nsr"
    }}>{`National Stop Register (NSR)`}</h1>
    <p>{`National Stop Register (NSR) is the master database for public transport stops in Norway and is primarily used to store and redistribute detailed information regarding the infrastructure of a stop place.`}</p>
    <p>{`The data is created and maintained by the counties (fylkeskommune) with Entur acting as the overall administrator. All stops currently in active use by public transport services are required to present and active in NSR, regardless of physical condition.
The purpose of having a master database for all stops is to avoid duplication of stop data across providers and to make it easier for new operators to plan, and establish routes. The basic information stored in NSR is ID, name, relation, and position, but other data such as shelter or wheelchair accessibility is also handled.`}</p>
    <p>{`ℹ Navigate to `}<a parentName="p" {...{
        "href": "https://stoppested.entur.org"
      }}>{`https://stoppested.entur.org`}</a>{` to explore the contents of NSR.`}</p>
    <h1 {...{
      "id": "netex-publications"
    }}>{`NeTEx publications`}</h1>
    <p>{`All stop place data is exported daily to `}<a parentName="p" {...{
        "href": "/stops-and-timetable-data"
      }}>{`NeTEx files`}</a>{` that are freely available under NLOD license. This is the preferred way to consume stop place data.`}</p>
    <h1 {...{
      "id": "api"
    }}>{`API`}</h1>
    <p>{`Please refer to `}<a parentName="p" {...{
        "href": "/stop-places-v1-read"
      }}>{`the API page`}</a>{` for API documentation.`}</p>
    <p>{`Alternatively, stop places information is also available in the `}<a parentName="p" {...{
        "href": "/pages-geocoder-intro"
      }}>{`Geocoder`}</a>{`-, and `}<a parentName="p" {...{
        "href": "/pages-journeyplanner-journeyplanner"
      }}>{`Journey Planner API`}</a>{`s.`}</p>
    <p><em parentName="p">{`Note: The GraphQL API that was previously advertised here is not a general purpose API for consuming stop places data.
Excessive use of the GraphQL API may be blocked without prior notice.`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      